import { pathNames } from "./pathNames.enum";

type IPath = { path: string; params?: string[] };

export const paths: {
  [key in pathNames]: IPath;
} = {
  [pathNames.LOGIN_INDEX]: {
    path: "/login",
  },
  [pathNames.ORDERS_INDEX]: {
    path: "/orders",
  },
};
