'use client';
import { AuthProvider } from '@/packages/components/contexts/auth.context';
import './globals.css';
import { TrpcProvider } from '@/packages/trpc/trpc-provider';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <TrpcProvider>
        <AuthProvider>
          <body>{children}</body>
        </AuthProvider>
      </TrpcProvider>
    </html>
  );
}
