import { pathNames } from "./pathNames.enum";
import { pathParams } from "./pathParams.const";
import { paths } from "./paths.const";

export const getRoute = (props: {
  path: pathNames;
  params?: { [key in pathParams]?: string };
  searchParams?: { [key: string]: string };
  options?: { format?: "absolute" | "relative" };
}) => {
  const {
    path,
    params,
    searchParams,
    options = { format: "relative" },
  } = props;

  const route = paths[path];

  let result = "";

  if (route.params) {
    result = route.path;

    if (!params) throw `${path} requires parameters to be supplied`;

    route.params.map((param) => {
      const value = params[param as pathParams];

      if (!value) throw `${path} is missing a parameter for ${param}`;

      result = result.replaceAll(param, value);
    });
  } else {
    result = route.path;
  }

  if (searchParams) {
    let query: string[] = [];

    Object.keys(searchParams).map((key) => {
      query.push(`${key}=${searchParams[key]}`);
    });

    query.join("&");

    result = `${result}?${query}`;
  }

  return options.format === "relative"
    ? result
    : `${window.location.protocol}//${window.location.host}${result}`;
};
