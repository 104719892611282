'use client';
import { ReactNode, useState } from 'react';
import { AdminUser } from '@database';
import { useRouter } from 'next/navigation';
import { trpc } from '@/packages/trpc';
import { getRoute, pathNames } from '@/packages/utils';
import { createContext } from '@/packages/utils/react/createContext';
import { AUTH_TOKEN_COOKIE } from '@/packages/constants/auth-token-cookie.const';
import { setToken } from '@/packages/trpc/trpc-provider';
import { setCookie } from 'nookies';
import { LoginUserInput } from '@/packages/api/src';

export const [AuthContextProvider, useAuth] = createContext<{
  isAuthenticated: boolean;
  isLoggingIn: boolean;
  user: any;
  login(values: LoginUserInput): void;
  loginError: string;
  logout(): void;
}>();

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<Pick<AdminUser, 'id'> | null>(null);

  const [error, setError] = useState<string>('');

  const router = useRouter();

  const { isLoading: isSubmitting, mutate: loginUser } =
    trpc.auth.loginUser.useMutation({
      onSuccess(data) {
        setCookie(null, AUTH_TOKEN_COOKIE, data.accessToken, {
          path: '/',
        });

        setToken(data.accessToken);

        router.push(getRoute({ path: pathNames.ORDERS_INDEX }));
      },
      onError(error) {
        setError(error.message);
      },
    });

  const login = async (values: LoginUserInput) => {
    loginUser(values);
  };

  const logout = () => {};

  return (
    <AuthContextProvider
      value={{
        isAuthenticated: !!user,
        isLoggingIn: isSubmitting,
        loginError: error,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
