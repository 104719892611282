'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, getFetch, loggerLink } from '@trpc/client';
import { useEffect, useState } from 'react';
import superjson from 'superjson';
import { trpc } from '.';
import Cookies from 'js-cookie';
import { AUTH_TOKEN_COOKIE } from '../constants/auth-token-cookie.const';

let token: string;

export const setToken = (newToken: string) => {
  token = newToken;
};

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { staleTime: 5000 } },
      })
  );

  const url = `/api/trpc`;

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url,
          headers: () => {
            return {
              ...(token && { Authorization: token }),
            };
          },
          fetch: async (input, init?) => {
            const fetch = getFetch();
            return fetch(input, {
              ...init,
              credentials: 'include',
            });
          },
        }),
      ],
      transformer: superjson,
    })
  );

  useEffect(() => {
    const token = Cookies.get(AUTH_TOKEN_COOKIE);

    if (token) setToken(token);
  }, []);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
