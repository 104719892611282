import { format, parseISO } from "date-fns";

import { DATE_FORMAT } from "./dateFormat.const";

export const formatDate = ({
  date,
  outputFormat = DATE_FORMAT,
}: {
  date: Date | null;
  outputFormat?: string;
}) => {
  return date ? format(date, outputFormat) : null;
};
